import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Gallery.css"; // Import the CSS file
import LoadingScreen from "../components/LoadingScreen";
import ReactGA from "react-ga4";

const Gallery = () => {
  ReactGA.send({ hitType: "pageview", page: "/gallery", title: "Gallery" });
  const [collections, setCollections] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionsResponse = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/galleries`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        const collectionsData = collectionsResponse.data.data;

        const detailedCollections = await Promise.all(
          collectionsData.map(async (collection) => {
            const collectionDetailResponse = await axios.get(
              `${process.env.REACT_APP_CMS_URL}/api/galleries/${collection.id}?populate=*`,
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
                },
              }
            );
            return collectionDetailResponse.data.data;
          })
        );

        // Sort collections by id in descending order
        detailedCollections.sort((a, b) => b.id - a.id);

        setCollections(detailedCollections);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpen = (images, index) => {
    setCurrentImages(images);
    setCurrentIndex(index);
    setOpen(true);
    document.body.classList.add("scroll-visible");
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImages([]);
    setCurrentIndex(0);
    document.body.classList.remove("scroll-visible");
  };

  return (
    <div className="gallery-bg">
      {loading ? (
        <LoadingScreen />
      ) : (
        <Container>
          {collections.map((collection) => (
            <Box key={collection.id} mb={5} p={2} borderRadius={2}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                className="collection-title"
              >
                {collection.attributes.Title}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                gutterBottom={true}
                className="collection-description"
              >
                {collection.attributes.Description}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                gutterBottom={true}
                className="collection-date"
              >
                {collection.attributes.Date}
              </Typography>
              <Masonry
                columns={{ xs: 1, sm: 2, md: 4 }}
                spacing={1}
                sx={{ width: "auto" }}
              >
                {collection.attributes.images.data.map((image, index) => (
                  <div
                    key={image.id}
                    onClick={() =>
                      handleOpen(
                        collection.attributes.images.data.map(
                          (img) => img.attributes.url
                        ),
                        index // Pass the index of the clicked image
                      )
                    }
                  >
                    <img
                      src={image.attributes.formats.thumbnail.url}
                      alt={image.attributes.name}
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                ))}
              </Masonry>
            </Box>
          ))}

          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent className="carousel-dialog-content">
              <Carousel selectedItem={currentIndex}>
                {currentImages.map((image, index) => (
                  <div key={index} className="carousel-image-container">
                    <img
                      src={image}
                      alt={`carousel-${index}`}
                      className="carousel-image"
                    />
                  </div>
                ))}
              </Carousel>
            </DialogContent>
          </Dialog>
        </Container>
      )}
    </div>
  );
};

export default Gallery;
