import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem, ListItemIcon, FormControl } from "@mui/material";
import GangaramayaFooter from "./GangaramayaFooter";
import LoadingScreen from "../../components/LoadingScreen";
import GangaramayaHeader from "./GangaramayaHeader";
import "./GangaramayaPage.css";
import ReactGA from "react-ga4";
import { languages } from "./languages";

const GangaramayaInfo = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/gangaramaya",
    title: "Gangaramaya Information Directory",
  });
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [language, setLanguage] = useState("English");
  const titleKey = `Title${language}`;

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/gangaramaya-qrs/?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching post", error);
      }
    };

    fetchPost();
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleTitleClick = (id) => {
    navigate(`/gangaramaya/${id}`);
  };

  if (!data) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <>
      <GangaramayaHeader />
      <div className="gangaramaya-page">
        <div className="lang-container">
          <div></div>
          <FormControl
            variant="outlined"
            sx={{
              m: 1, // Remove margin
              minWidth: "auto", // Remove min width
              padding: 0,
              border: 1,
              borderColor: "#d3d3d3 ",
              borderRadius: 1,
            }}
          >
            <Select
              value={language}
              onChange={handleLanguageChange}
              className="language-button"
              autoWidth
              renderValue={(selected) => {
                const selectedLang = languages.find(
                  (lang) => lang.value === selected
                );
                return (
                  <img
                    src={selectedLang.flag}
                    alt={`${selectedLang.label} flag`}
                    style={{ height: 30 }}
                  />
                );
              }}
              sx={{
                padding: 0,
                borderRadius: 1,
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {languages.map((lang) => (
                <MenuItem
                  key={lang.value}
                  value={lang.value}
                  sx={{ fontSize: "1.5rem" }}
                >
                  <ListItemIcon>
                    <img
                      src={lang.flag}
                      alt={`${lang.label} flag`}
                      style={{ height: 25, marginTop: 5 }}
                    />
                  </ListItemIcon>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="gangaramaya-content">
          <h1>Information Directory</h1>
          <ol>
            {data.map((item) => (
              <li
                key={item.id}
                onClick={() => handleTitleClick(item.id)}
                style={{
                  cursor: "pointer",
                  margin: "10px 0",
                  fontSize: "1rem",
                }}
              >
                {item.attributes[titleKey]}
              </li>
            ))}
          </ol>
        </div>
      </div>
      <GangaramayaFooter />
    </>
  );
};

export default GangaramayaInfo;
