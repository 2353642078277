import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./NewsAndMedSchedule.css";

const NewsAndMedSchedule = () => {
  const [activeContent, setActiveContent] = useState(null);
  const [newsContent, setNewsContent] = useState(null);
  const [medContent, setMedContent] = useState(null);
  const [popContent, setPopContent] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isPopupClosing, setIsPopupClosing] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchNewsContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/notices/2?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        const content = response.data.data.attributes.Description;
        setNewsContent(content);
      } catch (error) {
        console.error("Error fetching news content:", error);
      }
    };

    fetchNewsContent();
  }, []);

  useEffect(() => {
    const fetchMedContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/notices/3?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        const content = response.data.data.attributes.Description;
        setMedContent(content);
      } catch (error) {
        console.error("Error fetching meditation content:", error);
      }
    };

    fetchMedContent();
  }, []);

  useEffect(() => {
    const fetchPopContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/notices/4?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        const content = response.data.data.attributes.Description;
        setPopContent(content);
      } catch (error) {
        console.error("Error fetching news content:", error);
      }
    };

    fetchPopContent();
  }, []);

  useEffect(() => {
    // Auto simulate "Meditation Schedule" button click when the component mounts
    setActiveContent("news");
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleButtonClick = (type) => {
    if (activeContent === type) return;

    if (type === null) {
      // Close button clicked
      setIsClosing(true);
      setTimeout(() => {
        setActiveContent(null);
        setIsClosing(false);
      }, 500); // Duration of the close animation
    } else {
      // Content change
      setActiveContent(type);
    }
  };

  const closePopup = () => {
    setIsPopupClosing(true);
    setTimeout(() => {
      setShowPopup(false);
      setIsPopupClosing(false);
    }, 500); // Duration of the popup close animation
  };

  return (
    <div className="nm-note-container">
      <div className="nm-button-container">
        <button
          className={`nm-note-button ${
            activeContent === "news" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("news")}
        >
          News and Events
        </button>
        <button
          className={`nm-note-button ${
            activeContent === "meditation" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("meditation")}
        >
          Meditation Schedule
        </button>
      </div>

      {activeContent && (
        <div
          className={`nm-scroll-animation ${isClosing ? "closing" : ""}`}
          ref={contentRef}
        >
          {activeContent === "news" && (
            <p
              className="nm-note-paragraph"
              dangerouslySetInnerHTML={{ __html: newsContent }}
            ></p>
          )}
          {activeContent === "meditation" && (
            <p
              className="nm-note-paragraph"
              dangerouslySetInnerHTML={{ __html: medContent }}
            ></p>
          )}
          <button
            className="nm-note-close-button"
            onClick={() => handleButtonClick(null)}
          >
            Close
          </button>
        </div>
      )}

      {showPopup && popContent && (
        <div className="popup-overlay">
          <div
            className={`popup ${
              isPopupClosing ? "popup-closing" : "popup-opening"
            }`}
          >
            <p className="popup-title">Notice</p>
            <button className="popup-close" onClick={closePopup}>
              &times;
            </button>
            <div
              className="popup-content"
              dangerouslySetInnerHTML={{ __html: popContent }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsAndMedSchedule;
