import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Blog.css";
import ReactGA from "react-ga4";
import LoadingScreen from "../components/LoadingScreen";

const FullArticle = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/blog/:id",
    title: "Full Article",
  });
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  });

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/blog-posts/${id}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        setPost(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching post", error);
      }
    };

    const fetchRecentPosts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/blog-posts?sort[0]=id:desc&pagination[limit]=5`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        setRecentPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching recent posts", error);
      }
    };

    fetchPost();
    fetchRecentPosts();
  }, [id]);

  if (!post)
    return (
      <div>
        <LoadingScreen />
      </div>
    );

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleRecentPostClick = (postId) => {
    navigate(`/blog/${postId}`);
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" p={2}>
        <IconButton onClick={handleBackClick} sx={{ marginRight: 2 }}>
          {isDesktop ? (
            <ArrowBackIcon fontSize="large" sx={{ color: "#c1911a" }} />
          ) : (
            <ArrowBackIcon fontSize="medium" sx={{ color: "#c1911a" }} />
          )}
        </IconButton>
        {isDesktop && (
          <Typography variant="h3">{post.attributes.Title}</Typography>
        )}
        {!isDesktop && (
          <Typography variant="h5" sx={{ marginLeft: 2 }}>
            {post.attributes.Title}
          </Typography>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box flex={1} p={2}>
          <Typography variant="subtitle1" gutterBottom>
            By {post.attributes.Author} on{" "}
            {new Date(post.attributes.Date).toLocaleDateString()}
          </Typography>
          <img
            src={post.attributes.Image.data.attributes.url}
            alt={post.attributes.Title}
            style={{ width: "100%", height: "auto", marginBottom: "20px" }}
          />
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: post.attributes.Content }}
          />
        </Box>
        {isDesktop && (
          <Box
            pt={6}
            pl={2}
            pr={2}
            pb={2}
            sx={{ width: "25%", maxHeight: "80vh", overflowY: "auto" }}
          >
            <Typography variant="h5" gutterBottom sx={{ color: "#c1911a" }}>
              Recent Posts
            </Typography>
            <List>
              {recentPosts.map((recentPost) => (
                <ListItem
                  button
                  key={recentPost.id}
                  onClick={() => handleRecentPostClick(recentPost.id)}
                >
                  <ListItemText primary={recentPost.attributes.Title} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default FullArticle;
