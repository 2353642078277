import flag_en from "./flags/en.svg";
import flag_lk from "./flags/lk.svg";
import flag_cn from "./flags/cn.svg";
import flag_jp from "./flags/jp.webp";
import flag_in from "./flags/in.png";

export const languages = [
  { value: "English", label: "English", flag: flag_en },
  { value: "Sinhala", label: "Sinhala", flag: flag_lk },
  { value: "Chinese", label: "Chinese", flag: flag_cn },
  { value: "Japanese", label: "Japanese", flag: flag_jp },
  { value: "Hindi", label: "Hindi", flag: flag_in },
];
