import React from "react";
import { useEffect } from "react";
import "./Donations.css";
import Wide_shot from "../assets/Wide_shot.png";
import pay_types from "../assets/payment_types.png";
import ReactGA from "react-ga4";

const Donations = () => {
  ReactGA.send({ hitType: "pageview", page: "/donations", title: "Donations" });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  });

  return (
    <div className="donations-bg">
      <p className="donations-header">Donations</p>
      <div className="donations-container">
        <p className="donations-text">To make a donation please click here.</p>
        <center>
          <form
            className="donations-form"
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
          >
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input
              type="hidden"
              name="hosted_button_id"
              value="XEE3PRE6C8EK8"
            />
            <button type="submit" className="donations-button">
              Donate
            </button>
            <img
              src={pay_types}
              alt="payment_types"
              className="donations-pay-types-img"
            />
          </form>
        </center>
        <div className="donations-followup">
          <p className="don-fol-text">
            We would like to continue to invite you to make donation, to support
            the service of the center. You can donate by Check, cash, bank
            transfer, PayPal, and Zella.
          </p>
        </div>
        <div className="don-text-img">
          <p className="donations-text-right">
            Your generosity, no matter how much, benefits many people.
          </p>
          <img
            src="https://aibmc-content.nyc3.cdn.digitaloceanspaces.com/med_9_07_nr3_e2b362a774.jpg"
            alt="donation"
            className="donations-img1"
          />
        </div>
        <img src={Wide_shot} alt="donation" className="donations-wide-img" />
      </div>
    </div>
  );
};

export default Donations;
