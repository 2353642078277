// src/Splash.js
import React from "react";
import "./Splash.css";
import logo from "../assets/DharmachakraShaded.png"; // Adjust the path to your logo

const Splash = ({ fadeOut }) => {
  return (
    <div className={`loading ${fadeOut ? "fade-out" : ""}`}>
      <img src={logo} alt="Company Logo" className="spinner" />
      <br />
      {/* <div className="spinner"></div> */}
    </div>
  );
};

export default Splash;
