import React from "react";
import "./GangaramayaFooter.css";

const GangaramayaFooter = () => {
  return (
    <footer className="gan-footer">
      <p className="gan-text">
        A project by&nbsp;
        <a
          href="https://www.meditationforyou.org/"
          target="_blank"
          rel="noopener noreferrer"
          className="gan-link"
        >
          <b>Arizona International Buddhist Meditation Center</b>
        </a>
      </p>
      <p className="gan-text2">
        Designed & developed by&nbsp;
        <a
          href="https://www.isurugunarathne.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="gan-link"
        >
          <b>Isuru Gunarathne</b>
        </a>
      </p>
    </footer>
  );
};

export default GangaramayaFooter;
