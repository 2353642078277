import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DharmachakraShaded from "../assets/DharmachakraShaded.png";
import Logo_white from "../assets/Logo-whitebg.jpg";
import "./NavBar.css";
import { BiSolidPhone } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  // md - 960px, sm - 600px, xs - 0px
  const isNotTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        height: "100%",
        width: isNotTablet ? "19rem" : "22rem",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <List>
        <ListItem style={{ display: "flex", justifyContent: "left" }}>
          <img
            src={Logo_white}
            alt="Logo"
            style={{ width: "50%", height: "auto", marginBottom: "10px" }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/"
          onClick={toggleDrawer}
          key={"Home"}
        >
          <ListItemText
            primary="Home"
            sx={{
              color: activePath === "/" ? "goldenrod" : "#050505",
            }}
            primaryTypographyProps={{
              fontSize: "1.1rem",
              borderBottom:
                activePath === "/"
                  ? "1px solid goldenrod"
                  : "1px solid #808080",
            }}
          />
        </ListItem>

        {["Teachers", "Blog", "Links", "Gallery", "Donations"].map((text) => {
          const path = `/${text.toLowerCase()}`;
          return (
            <ListItem
              button
              component={Link}
              to={path}
              onClick={toggleDrawer}
              key={text}
            >
              <ListItemText
                primary={text}
                sx={{
                  color: activePath === path ? "goldenrod" : "#050505",
                }}
                primaryTypographyProps={{
                  fontSize: "1.1rem",
                  borderBottom:
                    activePath === path
                      ? "1px solid goldenrod"
                      : "1px solid #808080",
                }}
              />
            </ListItem>
          );
        })}
        <ListItem
          button
          component={Link}
          to="https://www.brownbearsw.com/cal/AIBMC_Calendar"
          onClick={toggleDrawer}
          key={"Calendar"}
        >
          <ListItemText
            primary="Calendar"
            sx={{
              color: activePath === "/calendar" ? "goldenrod" : "#050505",
            }}
            primaryTypographyProps={{
              fontSize: "1.1rem",
              borderBottom:
                activePath === "/calendar"
                  ? "1px solid goldenrod"
                  : "1px solid #808080",
            }}
          />
        </ListItem>
        <ListItem className="nav-contact">
          <div className="nav-icon-text">
            <BiSolidPhone className="nav-drawer-icon" />
            <a href="tel:480-626-4153" className="nav-contact-text">
              480-626-4153
            </a>
          </div>
          <div className="nav-icon-text">
            <BiMailSend className="nav-drawer-icon" />
            <a
              href="mailto:info@meditationforyou.org"
              className="nav-contact-text"
            >
              info@meditationforyou.org
            </a>
          </div>
        </ListItem>
      </List>
    </div>
  );

  const pages = [
    { name: "HOME", path: "/" },
    { name: "TEACHERS", path: "/teachers" },
    { name: "BLOG", path: "/blog" },
    { name: "LINKS", path: "/links" },
    { name: "GALLERY", path: "/gallery" },
    { name: "DONATIONS", path: "/donations" },
    {
      name: "CALENDAR",
      path: "https://www.brownbearsw.com/cal/AIBMC_Calendar",
    },
  ];

  return (
    <div className="nav-bar">
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#f8f8f8",
          paddingTop: "10px",
          paddingBottom: "10px",
          opacity: 1,
          boxShadow: "0px 5px 12px rgba(0, 0, 0, 0.1)", // Added shadow
        }}
        elevation={0}
        className="app-bar"
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <Link
              to="/"
              className="logo-link"
              style={{
                color: "grey",
                textDecoration: "none",
              }}
            >
              <img
                src={DharmachakraShaded}
                alt="DharmachakraShaded"
                style={{ height: "60px", verticalAlign: "middle" }}
              />
            </Link>
          </Typography>
          {isMobile && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{ color: "orange", fontSize: "2.4rem" }} // Increased size
            >
              {drawerOpen ? (
                <CloseIcon
                  sx={{ fontSize: "inherit", zIndex: 1, color: "red" }}
                />
              ) : (
                <MenuIcon sx={{ fontSize: "inherit" }} />
              )}
            </IconButton>
          )}
          {!isMobile && (
            <>
              {pages.map(({ name, path }) => (
                <Link
                  to={path}
                  style={{
                    textDecoration: "none",
                    margin: "0 10px",
                    fontSize: "1.2rem",
                    position: "relative",
                  }}
                  key={path}
                  className={activePath === path ? "active-link" : "other-link"}
                >
                  {name}
                </Link>
              ))}
            </>
          )}
        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
          {drawer}
        </Drawer>
      )}
      <Toolbar />
    </div>
  );
};

export default NavBar;
