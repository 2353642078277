import React from "react";
import "./Mission.css";

const Mission = () => {
  return (
    <div>
      <h1 className="mission-header">Our Mission</h1>
      <p className="mission-paragraph">
        The mission of the Arizona International Buddhist Meditation Center is
        to teach the practical benefits of Buddhist meditation and the Dhamma.
        The Center is non-sectarian and open to people of all races,
        nationalities, ethnicities, and religions.
      </p>
      <p className="mission-paragraph">
        We offer instructional programs in meditation through regularly
        scheduled meditation, Dhamma talks, and special programs presented by
        visiting Buddhist monks whose experience and knowledge will help
        practitioners enrich their practice immensely. The Center can provide
        accommodation and a proper environment for mental relaxation and our
        library of Buddhist books will be available for the use of all
        practitioners.
      </p>
    </div>
  );
};

export default Mission;
