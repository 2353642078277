import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Select, MenuItem, ListItemIcon, FormControl } from "@mui/material";
import GangaramayaFooter from "./GangaramayaFooter";
import LoadingScreen from "../../components/LoadingScreen";
import "./GangaramayaPage.css";
import GangaramayaHeader from "./GangaramayaHeader";
import { IoMdArrowBack } from "react-icons/io";
import ReactGA from "react-ga4";
import { languages } from "./languages";

const GangaramayaPage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/gangaramaya/:number",
    title: "Gangaramaya Info Screen",
  });

  const { number } = useParams();
  const [data, setData] = useState(null);
  const [language, setLanguage] = useState("English");
  const titleKey = `Title${language}`;

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/gangaramaya-qrs/${number}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        setData(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching post", error);
      }
    };

    fetchPost();
  }, [number]);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  if (number < 1 || number > 16) {
    return <h1>Page Not Found</h1>;
  }

  if (!data) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <>
      <GangaramayaHeader />
      <div className="gangaramaya-page">
        <div className="lang-container">
          <div></div>
          <FormControl
            variant="outlined"
            sx={{
              m: 1, // Remove margin
              minWidth: "auto", // Remove min width
              padding: 0,
              border: 1,
              borderColor: "#d3d3d3 ",
              borderRadius: 1,
            }}
          >
            <Select
              value={language}
              onChange={handleLanguageChange}
              className="language-button"
              autoWidth
              renderValue={(selected) => {
                const selectedLang = languages.find(
                  (lang) => lang.value === selected
                );
                return (
                  <img
                    src={selectedLang.flag}
                    alt={`${selectedLang.label} flag`}
                    style={{ height: 30 }}
                  />
                );
              }}
              sx={{
                padding: 0, // Remove padding
                borderRadius: 1, // Remove border radius
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: 1, // Remove padding inside select
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove border if necessary
                },
              }}
            >
              {languages.map((lang) => (
                <MenuItem
                  key={lang.value}
                  value={lang.value}
                  sx={{ fontSize: "1.5rem" }}
                >
                  <ListItemIcon>
                    <img
                      src={lang.flag}
                      alt={`${lang.label} flag`}
                      style={{ height: 25, marginTop: 5 }}
                    />
                  </ListItemIcon>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Show the content in data constant */}
        {data && (
          <div className="gangaramaya-content">
            {/* add a back button to go to /gangaramaya */}
            <div className="gangaramaya-back-container">
              <a href="/gangaramaya" className="gangaramaya-back-button">
                <IoMdArrowBack />
                Back
              </a>
            </div>
            <h2 className="gangaramaya-content-header">
              {data.attributes[titleKey]}
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: data.attributes[language] }}
            />
          </div>
        )}
      </div>
      <GangaramayaFooter />
    </>
  );
};

export default GangaramayaPage;
