import React, { useEffect, useState, useRef } from "react";
import "./Carousel.css";

const Carousel = ({ url, text }) => {
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isImageFadingOut, setIsImageFadingOut] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const currentRef = carouselRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsImageFadingOut(true); // Trigger fade-out animation for image
            setIsTextVisible(false); // Hide text

            setTimeout(() => {
              setIsImageFadingOut(false); // Remove image fade-out class after animation
              setIsTextVisible(true); // Show text after image has changed
            }, 100); // Wait for fade-out animation to complete before changing content
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isTextVisible && !isImageFadingOut) {
      setTimeout(() => {
        setIsButtonVisible(true); // Show button after text has faded in
      }, 1000); // Adjust this delay as needed to synchronize with text fade-in
    } else {
      setIsButtonVisible(false); // Hide button when text is not visible
    }
  }, [isTextVisible, isImageFadingOut]);

  const scrollToBottom = () => {
    const targetDiv = document.querySelector(".target-div");
    targetDiv.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div ref={carouselRef}>
      <div className="image-container">
        <img
          src={url}
          alt="Meditating Buddha"
          className={`meditating-buddha fade-in-image`}
        />
      </div>
      <div className="welcome-overlay">
        <p className={`welcome-text ${isTextVisible ? "fade-in-text" : ""}`}>
          {text}
        </p>
        {isButtonVisible && (
          <button
            className="learn-more-button fade-in-button"
            onClick={scrollToBottom}
          >
            Learn More
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
