import React, { useEffect, useState } from "react";
import "./PointsToPonder.css";
import Point from "./Point";
import axios from "axios";
import LoadingScreen from "./LoadingScreen";

const PointsToPonder = () => {
  const [points, setPoints] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/points`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        setPoints(response.data.data);
      } catch (error) {
        console.error("Error fetching points:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="pop-container">
      {points.length === 0 && <LoadingScreen />}
      <p className="pop-heading">Points to Ponder</p>
      {points.map((point) => (
        <Point
          key={point.id}
          title={point.attributes.Title}
          description={point.attributes.Description}
        />
      ))}
    </div>
  );
};

export default PointsToPonder;
