import React from "react";
import { useEffect } from "react";
import "./Calendar.css";
import ReactGA from "react-ga4";

const Calendar = () => {
  ReactGA.send({ hitType: "pageview", page: "/calendar", title: "Calendar" });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  });

  return (
    <div className="calendar-container">
      <p className="calendar-title">AIBMC Calendar</p>
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FColombo&bgcolor=%23ffffff&showTitle=0&showPrint=0&showCalendars=0&src=NGQxOTQ1ODE5MzJiOGM2MzVmNGQ3YTIyNTQwNzQ2YmU0NmM2MmNiNjM2YTNhM2ZmZWU2YjEzMzRkNGE5MTA4NkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23A79B8E"
        style={{ border: 0 }}
        width="100%"
        // height should be adjusted based on the device screen size
        height="100%"
        frameborder="0"
        title="Calendar"
      ></iframe>
    </div>
  );
};

export default Calendar;
