import React, { useEffect } from "react";
import "./HomePage.css"; // Import the CSS file
import Quote from "../components/Quote";
import About from "../components/About";
import Mission from "../components/Mission";
import Meditation from "../components/Meditation";
import BuddhismNote from "../components/BuddhismNote";
import NewsAndMedSchedule from "../components/NewsAndMedSchedule";
import Carousel from "../components/Carousel";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";

// import required modules
import { Autoplay, Scrollbar } from "swiper/modules";

import ReactGA from "react-ga4";

const HomePage = () => {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div className="home-bg">
      <div className="home-page-container">
        <Swiper
          className="mySwiper"
          autoplay={{ delay: 10000 }}
          scrollbar={{
            hide: true,
          }}
          loop={true}
          speed={1000}
          modules={[Autoplay, Scrollbar]}
        >
          <SwiperSlide>
            <Carousel
              url="https://aibmc-content.nyc3.cdn.digitaloceanspaces.com/Meditating_Buddha_67762fc7a0.jpg"
              text="Arizona International Buddhist Meditation Center"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Carousel
              url="https://aibmc-content.nyc3.cdn.digitaloceanspaces.com/Inside03_3d68e7418a.jpg"
              text="Embrace Mindfulness and Peace"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Carousel
              url="https://aibmc-content.nyc3.cdn.digitaloceanspaces.com/Inside02_f628858647.jpg"
              text="Explore the Peaceful Path of Meditation"
            />
          </SwiperSlide>
        </Swiper>

        <div className="target-div"></div>
        <Quote />
        <About />
        <NewsAndMedSchedule />
        <Mission />
        <Meditation />
        <BuddhismNote />
      </div>
    </div>
  );
};

export default HomePage;
