import React from "react";
import "./Meditation.css";
import meditationimage from "../assets/MeditationAI.jpg";

// for animation
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import "./Quote.css";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
  hidden: { opacity: 0, scale: 0.5 },
};

const imgVariant = {
  visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 1.2 } },
  sided: { opacity: 0, x: "-10vw" },
};
// end animation

const Meditation = () => {
  // animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    console.log("text1", inView);
    if (inView) {
      control.start("visible");
    } else {
      // control.start("hidden");
    }
  }, [control, inView]);

  // end animation

  return (
    <div className="meditation-container">
      <motion.div
        ref={ref}
        variants={imgVariant}
        initial="sided"
        animate={control}
        className="meditation-img-container "
      >
        <img
          src={meditationimage}
          alt="Meditation"
          className="meditation-image"
        />
      </motion.div>
      <motion.div
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
        className="med-text-container"
      >
        <p className="meditation-paragraph">
          Meditation, or Bhavana in the Buddha's language of Pali, means mental
          culture or development aimed at cleansing the mind of impurities and
          disturbances and cultivating the habits of concentration and
          mindfulness which leads eventually to the attainment of the highest
          wisdom through the realization of the Universal Truths of Sakyamuni
          Buddha's Teachings.
        </p>
        <p className="meditation-paragraph">
          As the Center develops we also intend to add yoga studies as an
          additional tool for stress reduction.
        </p>
      </motion.div>
    </div>
  );
};

export default Meditation;
