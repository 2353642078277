import React from "react";
import "./Quote.css";

// for animation
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { debounce } from "lodash";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
  hidden: { opacity: 0, scale: 0.5 },
};
// end animation

const Quote = () => {
  // animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    const debouncedHandleViewChange = debounce((inView) => {
      console.log("Element in view:", inView);
      if (inView) {
        control.start("visible");
      } else {
        // control.start("hidden");
      }
    }, 150);

    debouncedHandleViewChange(inView);

    // Cleanup function to cancel the debounced function on unmount
    return () => {
      debouncedHandleViewChange.cancel();
    };
  }, [inView, control]);

  // end animation
  return (
    <motion.div
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className="quote"
    >
      "All that we are is the result of what we have thought" <br />- Lord
      Buddha
    </motion.div>
  );
};

export default Quote;
