import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CssBaseline, useTheme } from "@mui/material";

import HomePage from "./pages/HomePage";
import Splash from "./pages/Splash";
import TeachersPage from "./pages/TeachersPage";
import LinksPage from "./pages/LinksPage";
import Gallery from "./pages/Gallery";
import Donations from "./pages/Donations";
import Calendar from "./pages/Calendar";
import Blog from "./pages/Blog";
import FullArticle from "./pages/FullArticle";
import GangaramayaPage from "./pages/gangaramaya/GangaramayaPage";
import GangaramayaInfo from "./pages/gangaramaya/GangaramayaInfo";
import Layout from "./components/Layout";
import ReactGA from "react-ga4";

const App = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      }, 1500);
    };

    handleLoad();
  }, []);

  return (
    <Router>
      {isLoading && <Splash fadeOut={fadeOut} />}
      <CssBaseline />
      <Layout>
        <main style={{ padding: theme.spacing(0) }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/teachers" element={<TeachersPage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<FullArticle />} />
            <Route path="/links" element={<LinksPage />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/donations" element={<Donations />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/gangaramaya" element={<GangaramayaInfo />} />
            <Route path="/gangaramaya/:number" element={<GangaramayaPage />} />
          </Routes>
        </main>
      </Layout>
    </Router>
  );
};

export default App;
