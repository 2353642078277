import React from "react";
import PropTypes from "prop-types";
import "./TeacherInfo.css";

const TeacherInfo = ({ title, image, description1, description2 }) => {
  const formatDescription = (description) => {
    return description.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="teacher-info-container">
      <h2 className="teacher-info-title">{title}</h2>
      <hr className="teacher-info-divider" />
      <div className="teacher-info-content">
        <img className="teacher-info-image" src={image} alt={title} />
        <p className="teacher-info-description">
          {formatDescription(description1)}
        </p>
      </div>
      {description2 && (
        <div className="teacher-info-description-full">
          <p>{formatDescription(description2)}</p>
        </div>
      )}
    </div>
  );
};

TeacherInfo.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description1: PropTypes.string.isRequired,
  description2: PropTypes.string,
};

export default TeacherInfo;
