import axios from "axios";
import React, { useEffect, useState } from "react";
import Masonry from "@mui/lab/Masonry";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import "./Blog.css";
import Banner from "../components/Banner";
import ReactGA from "react-ga4";

const Blog = () => {
  ReactGA.send({ hitType: "pageview", page: "/blog", title: "Blog" });
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const buffer = 10;
  const start = 0;
  const [current, setCurrent] = useState(start);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  });

  useEffect(() => {
    const fetchInitialPosts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CMS_URL}/api/blog-posts?pagination[start]=${start}&pagination[limit]=${buffer}&populate=*&sort[0]=id:desc`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
            },
          }
        );
        setPosts(response.data.data);
        console.log("initial data fetched", start, buffer);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts", error);
      }
    };

    fetchInitialPosts();
  }, [start, buffer]);

  const fetchNextPosts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/api/blog-posts?pagination[start]=${
          current + buffer
        }&pagination[limit]=${buffer}&populate=*&sort[0]=id:desc`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
          },
        }
      );
      if (response.data.data.length > 0) {
        setPosts((prevPosts) => [...prevPosts, ...response.data.data]);
        setCurrent(current + buffer);
      }

      console.log("new data fetched", current, buffer);
    } catch (error) {
      console.error("Error fetching next posts", error);
    }
  };

  const handleCardClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="blog-bg">
      {loading ? (
        <LoadingScreen />
      ) : (
        <div>
          {/* <Banner text="BLOG" /> */}
          <Banner
            text="Blog"
            backgroundImageUrl={
              "https://aibmc-content.nyc3.cdn.digitaloceanspaces.com/Buddhist_archive_5d31634f58.jpg"
            }
          />
          <Container>
            <Typography variant="h3" gutterBottom className="blog-header">
              Blog
            </Typography>
            <Masonry
              columns={{ xs: 2, sm: 3, md: 4 }}
              spacing={2}
              sx={{ width: "auto" }}
            >
              {posts.map((post) => (
                <Card
                  key={post.id}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      boxShadow: "0 0 18px rgba(0, 0, 0, 0.4)",
                    },
                  }}
                  onClick={() => handleCardClick(post.id)}
                >
                  <CardMedia
                    component="img"
                    image={
                      post.attributes.Image.data.attributes.formats.thumbnail
                        .url
                    }
                    alt={post.attributes.Title}
                  />
                  <CardContent>
                    <Typography variant="h5" sx={{ color: "#c1911a" }}>
                      {post.attributes.Title}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "#515151" }}>
                      {post.attributes.Author}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(post.attributes.Date).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Masonry>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                onClick={fetchNextPosts}
                sx={{
                  backgroundColor: "#c1911a",
                  color: "white",
                  marginBottom: "2rem",
                  "&:hover": {
                    backgroundColor: "#dbbb69",
                  },
                }}
              >
                Load More
              </Button>
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Blog;
