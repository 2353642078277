import React, { useState, useEffect } from "react";
import TeacherInfo from "../components/TeacherInfo";
import axios from "axios";
import "./TeachersPage.css";
import LoadingScreen from "../components/LoadingScreen";
import Banner from "../components/Banner";
import ReactGA from "react-ga4";

const TeachersPage = () => {
  ReactGA.send({ hitType: "pageview", page: "/teachers", title: "Teachers" });
  const [teacher1, setTeacher1] = useState(null);
  const [teacher2, setTeacher2] = useState(null);
  const [teacher3, setTeacher3] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const fetchTeacher1Data = async () => {
    try {
      console.log("Bearer token:", process.env.REACT_APP_CMS_KEY); // Verify the token is being loaded

      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/api/teachers/1?populate=*`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
          },
        }
      );

      const data = response.data.data.attributes;
      const imageUrl = data?.Image?.data?.attributes?.url;

      if (imageUrl) {
        setTeacher1({
          title: data.Title,
          image: imageUrl,
          description1: data.Description1,
          description2: data.Description2,
        });
      } else {
        console.error("Image URL is missing in the response");
      }
    } catch (error) {
      console.error("Error fetching teacher1 data:", error);
    }
  };

  const fetchTeacher2Data = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/api/teachers/2?populate=*`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
          },
        }
      );

      const data = response.data.data.attributes;
      const imageUrl = data?.Image?.data?.attributes?.url;

      if (imageUrl) {
        if (data.Description2) {
          setTeacher2({
            title: data.Title,
            image: imageUrl,
            description1: data.Description1,
            description2: data.Description2,
          });
        } else {
          setTeacher2({
            title: data.Title,
            image: imageUrl,
            description1: data.Description1,
          });
        }
      } else {
        console.error("Image URL is missing in the response");
      }
    } catch (error) {
      console.error("Error fetching teacher2 data:", error);
    }
  };

  const fetchTeacher3Data = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/api/teachers/3?populate=*`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
          },
        }
      );

      const data = response.data.data.attributes;
      const imageUrl = data?.Image?.data?.attributes?.url;

      if (imageUrl) {
        if (data.Description2) {
          setTeacher3({
            title: data.Title,
            image: imageUrl,
            description1: data.Description1,
            description2: data.Description2,
          });
        } else {
          setTeacher3({
            title: data.Title,
            image: imageUrl,
            description1: data.Description1,
          });
        }
      } else {
        console.error("Image URL is missing in the response");
      }
    } catch (error) {
      console.error("Error fetching teacher3 data:", error);
    }
  };

  useEffect(() => {
    fetchTeacher1Data();
    fetchTeacher2Data();
    fetchTeacher3Data();
  }, []);

  return (
    <div className="teacher-bg">
      {teacher1 ? (
        // <p className="teacher-header">Our Teachers</p>
        <Banner
          text="Our Teachers"
          backgroundImageUrl={
            "https://aibmc-content.nyc3.cdn.digitaloceanspaces.com/teacher_header_2_crop_544684bf46.jpg"
          }
        />
      ) : null}

      {teacher1 ? (
        <TeacherInfo
          title={teacher1.title}
          image={teacher1.image}
          description1={teacher1.description1}
          description2={teacher1.description2}
        />
      ) : (
        <LoadingScreen />
      )}
      {teacher2 ? (
        <TeacherInfo
          title={teacher2.title}
          image={teacher2.image}
          description1={teacher2.description1}
          description2={teacher2.description2}
        />
      ) : null}
      {teacher3 ? (
        <TeacherInfo
          title={teacher3.title}
          image={teacher3.image}
          description1={teacher3.description1}
          description2={teacher3.description2}
        />
      ) : null}
    </div>
  );
};

export default TeachersPage;
