import React, { useState } from "react";
import "./Point.css";

const Point = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="point-container">
      <button className="point-note-button" onClick={handleToggle}>
        {/* split the title at '-' or the word 'by' and show only the first half */}
        {title.split("-")[0].split("by")[0]}
      </button>
      {isOpen && (
        <div className={`point-scroll-animation ${isOpen ? "" : "closing"}`}>
          <p className="point-note-title">{title}</p>
          <p
            className="nm-note-paragraph"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
          <button className="point-note-close-button" onClick={handleToggle}>
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default Point;
