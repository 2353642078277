import React, { useEffect, useRef, useState } from "react";
import "./Banner.css";

const Banner = ({ text, backgroundImageUrl }) => {
  const [isVisible, setIsVisible] = useState(false);
  const bannerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    const currentBannerRef = bannerRef.current;

    if (currentBannerRef) {
      observer.observe(currentBannerRef);
    }

    return () => {
      if (currentBannerRef) {
        observer.unobserve(currentBannerRef);
      }
    };
  }, []);

  return (
    <div ref={bannerRef} className={`banner ${isVisible ? "visible" : ""}`}>
      <div
        className={`banner-image ${isVisible ? "visible" : ""}`}
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      />
      <div className={`banner-text ${isVisible ? "text-visible" : ""}`}>
        {text}
      </div>
    </div>
  );
};

export default Banner;
