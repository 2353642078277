import React from "react";
import { useEffect } from "react";
import ExternalLinks from "../components/ExternalLinks";
import PointsToPonder from "../components/PointsToPonder";
import "./Links.css";
import Banner from "../components/Banner";
import ReactGA from "react-ga4";

const LinksPage = () => {
  ReactGA.send({ hitType: "pageview", page: "/links", title: "Links" });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  });

  return (
    <div className="links-bg">
      <div>
        <Banner
          text="Links"
          backgroundImageUrl={
            "https://aibmc-content.nyc3.cdn.digitaloceanspaces.com/links_2_a599cad93b.jpg"
          }
        />
        <PointsToPonder />
        <ExternalLinks />
      </div>
    </div>
  );
};

export default LinksPage;
