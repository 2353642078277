import React from "react";
import "./About.css"; // Assuming you have a CSS file for styling
import logo from "../assets/Logo-whitebg.jpg";

// for animation
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { debounce } from "lodash";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
  hidden: { opacity: 0, scale: 0.5 },
};
// end animation

const About = () => {
  // animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    const debouncedHandleViewChange = debounce((inView) => {
      console.log("Element in view:", inView);
      if (inView) {
        control.start("visible");
      } else {
        // control.start("hidden");
      }
    }, 200);

    debouncedHandleViewChange(inView);

    // Cleanup function to cancel the debounced function on unmount
    return () => {
      debouncedHandleViewChange.cancel();
    };
  }, [inView, control]);

  // end animation
  return (
    <motion.div
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className="about-container"
    >
      <div className="about-logo-container">
        <img src={logo} alt="Logo" className="about-logo" />
      </div>

      <div className="about-text">
        The Arizona International Buddhist Meditation Center is a non-profit
        organization where all are invited to practice the Universal Truths of
        Sakyamuni Buddha's Teachings.
      </div>
    </motion.div>
  );
};

export default About;
