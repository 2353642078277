import React from "react";
import "./ExternalLinks.css";

const ExternalLinks = () => {
  const links = [
    {
      url: "http://www.accesstoinsight.org/",
      text: "Access to Insight - Readings in Theravada Buddhism",
    },
    {
      url: "http://www.audiodharma.org/",
      text: "Audio Dharma - Talks by Gil Fronsdale",
    },
    {
      url: "http://www.bodhimonastery.net/bm/",
      text: "Bodhi Monastery - Home of Bhikkhu Bodhi",
    },
    {
      url: "http://www.buddhanet.net/index.html",
      text: "Buddha Net - Buddhist Information Network",
    },
    {
      url: "http://www.bhavanasociety.org/",
      text: "Bhavana Society - Theravada Monastery and Meditation Center",
    },
    {
      url: "http://www.dharmanet.org/learning.html",
      text: "Dharma Net - On-line Learning Center",
    },
    {
      url: "http://www.dharmavijaya.org/index.htm",
      text: "Dharma Vijaya - Home of Ven. Piyananda",
    },
    {
      url: "http://www.dhamma.org/",
      text: "Goenka - Vipassana Centers",
    },
    {
      url: "http://dharma.org/ims/",
      text: "Insight Meditation Society",
    },
    {
      url: "http://www.watmetta.org/",
      text: "Metta Forest Monastery - Home of Thanissaro Bhikkhu",
    },
    {
      url: "http://www.palitext.com/",
      text: "Pali Text Society",
    },
    {
      url: "http://www.plumvillage.org/",
      text: "Plumb Village - Home of Thich Nhat Hanh",
    },
    {
      url: "http://www.buddhanet.net/audio-lectures.htm",
      text: "The 10 Lectures by Bhikku Bodhi - A MUST LISTEN",
    },
    {
      url: "http://www.wisebrain.org//",
      text: "Wise Brain",
    },
    {
      url: "http://www.zencast.org/",
      text: "Zencast",
    },
  ];

  return (
    <div className="ext-container">
      <p className="ext-heading">External Links</p>
      <ul className="ext-list">
        {links.map((link, index) => (
          <li key={index}>
            <a
              href={link.url}
              target="_blank"
              rel="noreferrer"
              className="external-link"
            >
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExternalLinks;
