import React, { useState, useRef, useEffect } from "react";
import "./BuddhismNote.css";

const BuddhismNote = () => {
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const noteRef = useRef(null);

  const toggleNote = () => {
    if (isNoteVisible) {
      setIsClosing(true);
      setTimeout(() => {
        setIsNoteVisible(false);
        setIsClosing(false);
      }, 500); // Duration of the close animation
    } else {
      setIsNoteVisible(true);
    }
  };

  useEffect(() => {
    if (isNoteVisible && noteRef.current) {
      noteRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isNoteVisible]);

  return (
    <div className="note-container">
      <button className="note-button" onClick={toggleNote}>
        A Note on Buddhism and Stress Reduction
      </button>

      {isNoteVisible && (
        <div
          className={`scroll-animation ${isClosing ? "closing" : ""}`}
          ref={noteRef}
        >
          <p className="note-paragraph">
            Stress is a modern phenomenon caused by the rapid pace at which the
            world is continually changing around us. We experience this rapid
            change both internally and externally, and it causes us to become
            tense, uncomfortable, and stressful. The result is, we all wind up
            suffering.
          </p>
          <p className="note-paragraph">
            In today’s materialistic world, we experience stress more than ever.
            To live a stress-free life is not an easy task for many of us
            because stress as a phenomenon remains unrecognized. It is now
            discovered that stress leads to other complications such as heart
            disease, substance abuse, marital discord, frustration, anger,
            violence, and even murder. Without proper training, we are unable to
            observe things as they occur around us. But are still quick to react
            to what we experience without observing the repercussions. Such
            blind reactions create chaos in life. The stress that gets
            accumulated in the human body and mind in this way needs to be
            discharged. Often, it gets discharged in the form of hatred,
            violence, and hostilities. Thus, on many occasions, our easily
            avoidable problems, unfortunately, end in human tragedy. This is a
            universal human problem that requires a universal approach to
            resolve it.
          </p>
          <p className="note-paragraph">
            Though people work hard to secure happiness, they end up in
            unhappiness. Most of these people are longing for temporary sensual
            pleasures and experience misery instead of finding lasting happiness
            (sukha). If one wants to be free from misery, one has to make a
            conscious decision to achieve this freedom. The Buddha’s teachings
            offer a way to eradicate misery altogether from our lives. Though
            the techniques have been available to us for over 2500 years, we can
            not sell or buy these techniques like medication. If one wants
            positive results, one has to learn and practice them seriously,
            ardently, and patiently. This practice leads the student to the
            destination of mental purity and mental tranquillity. Lasting
            happiness results from mental tranquility which is also the freedom
            of stress.
          </p>
          <p className="note-paragraph">
            With the availability of the teachings of the Buddha in the world
            today, it is necessary to have a conducive environment to engage
            ourselves in serious practice. It is not easy to find an environment
            truly conducive to meditation (although there are many commercial
            ventures that sell so-called meditation at a high price as if it
            were a medication to sell and administer). Many places do not seem
            to serve those who need this service the most, e.g., adolescents,
            the youth, and the elderly.
          </p>
          <p className="note-paragraph">
            Our intention is to provide a center for all to learn and practice
            the Buddhist techniques of Bhavana (mental training) for mental
            serenity and mind purification. Through this program, our intention
            is to serve all regardless of religious, cultural, or ethnic
            differences.
          </p>
          <button className="note-close-button" onClick={toggleNote}>
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default BuddhismNote;
