import React from "react";
import gangaramaya_logo from "../../assets/gangarama_web_logo.png";
import "./GangaramayaHeader.css";

const GangaramayaHeader = () => {
  return (
    <header className="gangaramaya-header">
      <a
        href="https://gangaramaya.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={gangaramaya_logo}
          alt="Gangaramaya"
          className="gangaramaya-header-logo"
        />
      </a>
    </header>
  );
};

export default GangaramayaHeader;
